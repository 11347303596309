// =======================================================================
// App Scripts - Requiring js modules
// =======================================================================
var Slideout = require('slideout');

// =======================================================================
// Helper functions
// =======================================================================

// Classie: class helper functions
(function (window) {
  'use strict';
  function classReg(className) {
    return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
  }

  // classList support for class management
  // although to be fair, the api sucks because it won't accept multiple classes at once
  var hasClass, addClass, removeClass;

  if ('classList' in document.documentElement) {
    hasClass = function (elem, c) {
      return elem.classList.contains(c);
    };
    addClass = function (elem, c) {
      elem.classList.add(c);
    };
    removeClass = function (elem, c) {
      elem.classList.remove(c);
    };
  }
  else {
    hasClass = function (elem, c) {
      return classReg(c).test(elem.className);
    };
    addClass = function (elem, c) {
      if (!hasClass(elem, c)) {
        elem.className = elem.className + ' ' + c;
      }
    };
    removeClass = function (elem, c) {
      elem.className = elem.className.replace(classReg(c), ' ');
    };
  }

  function toggleClass(elem, c) {
    var fn = hasClass(elem, c) ? removeClass : addClass;
    fn(elem, c);
  }

  var classie = {
    // full names
    hasClass: hasClass,
    addClass: addClass,
    removeClass: removeClass,
    toggleClass: toggleClass,
    // short names
    has: hasClass,
    add: addClass,
    remove: removeClass,
    toggle: toggleClass
  };

  // transport
  if (typeof define === 'function' && define.amd) {
    // AMD
    define(classie);
  } else {
    // browser global
    window.classie = classie;
  }
})(window);

function set_current () {
  var url = window.location.pathname;
  var currentFilename = url.substring(url.lastIndexOf('/')+1);
  var html = document.getElementById('document');
  var navs = ['main-menu', 'side-menu'];

  for ( var i = 0; i < navs.length; i++) {
    var menuItems = document.getElementById(navs[i]);
    var menuLinks = menuItems.getElementsByTagName('a');
    var currentLink = [];

    for (var x = -0; x < menuLinks.length; x++) {
      var linkPath = menuLinks[x].pathname;
      var fileLink = linkPath.substring(linkPath.lastIndexOf('/') + 1);
      currentLink.push(fileLink);
    }

    for (var y = 0; y < currentLink.length; y++) {
      if (currentLink[y] == currentFilename) {
        classie.add(menuLinks[y], 'is-current');
      } else if (currentFilename.indexOf('suite-')== 0) {
        classie.add(menuLinks[1], 'is-current');
      } else if (currentFilename == '') {
        classie.add(menuLinks[0], 'is-current');
      }
    }
  }
  var classPage = [
    'index',
    'rooms',
    'services',
    'restaurant',
    'booking',
    'contact',
    'gallery',
    'events'
  ];
  for (var z = -0; z < classPage.length; z++) {
    if (currentFilename.indexOf(classPage[z]) == 0) {
      classie.add(html, classPage[z]);
    } else if (currentFilename.indexOf('room-') == 0) {
      classie.add(html, classPage[1]);
    } else if (currentFilename.indexOf('booking-sent') == 0) {
      classie.add(html, classPage[4]);
    } else if (currentFilename.indexOf('policy') == 0) {
      classie.add(html, classPage[4]);
    } else if (currentFilename.indexOf('message-sent') == 0) {
      classie.add(html, classPage[5]);
    } else if (currentFilename == '') {
      classie.add(html, classPage[0]);
    }
  }
}
set_current();

// Preventing default behavior on event
function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

// =======================================================================
// Initialize and configure Slideout functionality
// =======================================================================
// Initialize Slideout (is defined elsewhere)
var slideout = new Slideout({
  'menu': document.getElementById('side-navigation'),
  'panel': document.getElementById('content-sb-shifter'),
  'padding': 256,
  'tolerance': 60,
  'side': 'right'
});

// Getting hamburger button
var hamburger = document.getElementById('hamburger-button');

// Using hamburger for toggling sidebar
hamburger.addEventListener('click', function () {
  hamburger.classList.toggle('is-active');
  slideout.toggle();
});

// =======================================================================
// Slideout indicator interface setup
// =======================================================================

/*
// With every load we set our interface current context
// true in order to the turn it off after it first appears
window.onload = function() { window.sbictx = true; };
// getting sbicookie if exist 
var sbicookie = getCookie('sbi') || false;
// getting sidebar interface indicator html element
var sbi = document.getElementById('sb-indicator');
// getting close sbi button html element
var sbiOff = document.getElementById('close-sbi');



// Show sidebar interface indicator
function indicatorOn() {
  var sbictx = window.sbictx;
  if (window.pageYOffset > 100 && sbictx === true && sbicookie === false) {
    disableScroll();
    sbi.style.display = 'block';
    sbi.style.zIndex = '1000';
    sbi.style.opacity = '0.999';
  }
}

// Remove sidebar interface indicator
function indicatorOff() {
  sbi.style.opacity = '0';
  sbi.addEventListener('transitionend', function() {
    sbi.style.removeProperty('display');
    sbi.style.removeProperty('z-index');
    sbi.style.removeProperty('opacity');
  }, true);
  window.sbictx = false;
  document.cookie = 'sbi=true';
  enableScroll();
}

// Respond to scroll
window.onscroll = indicatorOn;

// Close indicatorOff
sbiOff.onclick = indicatorOff;
*/
